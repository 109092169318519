/* tslint:disable */
/* eslint-disable */
/* @relayHash 96b16073863dc80f6b627c4be0dd574b */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommunityRouteQueryVariables = {};
export type CommunityRouteQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"UserTableContainer_query">;
};
export type CommunityRouteQuery = {
    readonly response: CommunityRouteQueryResponse;
    readonly variables: CommunityRouteQueryVariables;
};



/*
query CommunityRouteQuery {
  ...UserTableContainer_query
}

fragment InviteUsersContainer_settings on Settings {
  email {
    enabled
  }
  auth {
    integrations {
      local {
        enabled
        allowRegistration
        targetFilter {
          admin
        }
      }
    }
  }
}

fragment InviteUsersContainer_viewer on User {
  role
}

fragment SiteRoleActions_user on User {
  id
  username
  role
  membershipScopes {
    sites {
      id
      name
    }
  }
  moderationScopes {
    scoped
    sites {
      id
      name
    }
  }
}

fragment SiteRoleActions_viewer on User {
  id
  moderationScopes {
    sites {
      id
      name
    }
  }
}

fragment UserRoleChangeContainer_settings on Settings {
  multisite
}

fragment UserRoleChangeContainer_user on User {
  id
  username
  role
  moderationScopes {
    scoped
    sites {
      id
      name
    }
  }
  membershipScopes {
    scoped
    sites {
      id
      name
    }
  }
  ...SiteRoleActions_user
}

fragment UserRoleChangeContainer_viewer on User {
  id
  role
  moderationScopes {
    scoped
  }
  ...SiteRoleActions_viewer
}

fragment UserRowContainer_settings on Settings {
  ...UserStatusChangeContainer_settings
  ...UserRoleChangeContainer_settings
}

fragment UserRowContainer_user on User {
  ...UserStatusChangeContainer_user
  ...UserRoleChangeContainer_user
  id
  username
  email
  createdAt
  deletedAt
}

fragment UserRowContainer_viewer on User {
  ...UserStatusChangeContainer_viewer
  ...UserRoleChangeContainer_viewer
}

fragment UserStatusChangeContainer_settings on Settings {
  organization {
    name
  }
  multisite
}

fragment UserStatusChangeContainer_user on User {
  id
  role
  username
  moderationScopes {
    scoped
  }
  status {
    ban {
      active
      sites {
        id
        name
      }
    }
    suspension {
      active
    }
    premod {
      active
    }
    warning {
      active
    }
  }
  ...UserStatusContainer_user
}

fragment UserStatusChangeContainer_viewer on User {
  id
  role
  moderationScopes {
    scoped
    sites {
      id
      name
    }
  }
}

fragment UserStatusContainer_user on User {
  status {
    current
    ban {
      sites {
        id
      }
    }
  }
}

fragment UserTableContainer_query on Query {
  viewer {
    ...UserRowContainer_viewer
    ...InviteUsersContainer_viewer
    id
  }
  settings {
    multisite
    featureFlags
    ...InviteUsersContainer_settings
    ...UserRowContainer_settings
    id
  }
  users(first: 10) {
    edges {
      node {
        id
        ...UserRowContainer_user
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
    } as any), v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "sites",
        "plural": true,
        "selections": [
            (v0 /*: any*/),
            (v2 /*: any*/)
        ],
        "storageKey": null
    } as any), v4 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scoped",
            "storageKey": null
        } as any),
        (v3 /*: any*/)
    ], v5 = ({
        "alias": null,
        "args": null,
        "concreteType": "UserModerationScopes",
        "kind": "LinkedField",
        "name": "moderationScopes",
        "plural": false,
        "selections": (v4 /*: any*/),
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any), v7 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 10
        } as any)
    ], v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any), v9 = [
        (v8 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "CommunityRouteQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserTableContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "CommunityRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "featureFlags",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmailConfiguration",
                            "kind": "LinkedField",
                            "name": "email",
                            "plural": false,
                            "selections": [
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Auth",
                            "kind": "LinkedField",
                            "name": "auth",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AuthIntegrations",
                                    "kind": "LinkedField",
                                    "name": "integrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "LocalAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "local",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "allowRegistration",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AuthenticationTargetFilter",
                                                    "kind": "LinkedField",
                                                    "name": "targetFilter",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "admin",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v7 /*: any*/),
                    "concreteType": "UsersConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "username",
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserStatus",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "BanStatus",
                                                    "kind": "LinkedField",
                                                    "name": "ban",
                                                    "plural": false,
                                                    "selections": [
                                                        (v8 /*: any*/),
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SuspensionStatus",
                                                    "kind": "LinkedField",
                                                    "name": "suspension",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PremodStatus",
                                                    "kind": "LinkedField",
                                                    "name": "premod",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "WarningStatus",
                                                    "kind": "LinkedField",
                                                    "name": "warning",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "current",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserMembershipScopes",
                                            "kind": "LinkedField",
                                            "name": "membershipScopes",
                                            "plural": false,
                                            "selections": (v4 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "email",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "deletedAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "users(first:10)"
                },
                {
                    "alias": null,
                    "args": (v7 /*: any*/),
                    "filters": [
                        "role",
                        "status",
                        "query"
                    ],
                    "handle": "connection",
                    "key": "UserTable_users",
                    "kind": "LinkedHandle",
                    "name": "users"
                }
            ]
        },
        "params": {
            "id": "96b16073863dc80f6b627c4be0dd574b",
            "metadata": {},
            "name": "CommunityRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '0a93fa06bc3299443e30195026ca6e6f';
export default node;
