/* tslint:disable */
/* eslint-disable */
/* @relayHash 453f29f5cc26e7f40b85b16b8793b05f */

import { ConcreteRequest } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type UpdateUserRoleInput = {
    userID: string;
    role: USER_ROLE;
    clientMutationId: string;
};
export type UpdateUserRoleMutationVariables = {
    input: UpdateUserRoleInput;
};
export type UpdateUserRoleMutationResponse = {
    readonly updateUserRole: {
        readonly user: {
            readonly id: string;
            readonly role: USER_ROLE;
        };
        readonly clientMutationId: string;
    };
};
export type UpdateUserRoleMutation = {
    readonly response: UpdateUserRoleMutationResponse;
    readonly variables: UpdateUserRoleMutationVariables;
};



/*
mutation UpdateUserRoleMutation(
  $input: UpdateUserRoleInput!
) {
  updateUserRole(input: $input) {
    user {
      id
      role
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "UpdateUserRolePayload",
            "kind": "LinkedField",
            "name": "updateUserRole",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateUserRoleMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateUserRoleMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "453f29f5cc26e7f40b85b16b8793b05f",
            "metadata": {},
            "name": "UpdateUserRoleMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '868dd3360256378085bae10d4a52faef';
export default node;
