/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type NavigationContainer_viewer = {
    readonly role: USER_ROLE;
    readonly " $refType": "NavigationContainer_viewer";
};
export type NavigationContainer_viewer$data = NavigationContainer_viewer;
export type NavigationContainer_viewer$key = {
    readonly " $data"?: NavigationContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"NavigationContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '534f7fccf30aabf81973cec7ab1003a5';
export default node;
