/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type UserStatusChangeContainer_user = {
    readonly id: string;
    readonly role: USER_ROLE;
    readonly username: string | null;
    readonly moderationScopes: {
        readonly scoped: boolean;
    } | null;
    readonly status: {
        readonly ban: {
            readonly active: boolean;
            readonly sites: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
            }> | null;
        };
        readonly suspension: {
            readonly active: boolean;
        };
        readonly premod: {
            readonly active: boolean;
        };
        readonly warning: {
            readonly active: boolean;
        };
    };
    readonly " $fragmentRefs": FragmentRefs<"UserStatusContainer_user">;
    readonly " $refType": "UserStatusChangeContainer_user";
};
export type UserStatusChangeContainer_user$data = UserStatusChangeContainer_user;
export type UserStatusChangeContainer_user$key = {
    readonly " $data"?: UserStatusChangeContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserStatusChangeContainer_user">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any), v2 = [
        (v1 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "UserStatusChangeContainer_user",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "UserModerationScopes",
                "kind": "LinkedField",
                "name": "moderationScopes",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scoped",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "UserStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BanStatus",
                        "kind": "LinkedField",
                        "name": "ban",
                        "plural": false,
                        "selections": [
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Site",
                                "kind": "LinkedField",
                                "name": "sites",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SuspensionStatus",
                        "kind": "LinkedField",
                        "name": "suspension",
                        "plural": false,
                        "selections": (v2 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PremodStatus",
                        "kind": "LinkedField",
                        "name": "premod",
                        "plural": false,
                        "selections": (v2 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "WarningStatus",
                        "kind": "LinkedField",
                        "name": "warning",
                        "plural": false,
                        "selections": (v2 /*: any*/),
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserStatusContainer_user"
            }
        ],
        "type": "User",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '164a2503af7b979365ea3bae5cb18cd0';
export default node;
