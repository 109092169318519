/* tslint:disable */
/* eslint-disable */
/* @relayHash 9d2d7753262a19f7957fb97d39359edc */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type STORY_STATUS = "CLOSED" | "OPEN" | "%future added value";
export type StoryTableContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
    statusFilter?: STORY_STATUS | null;
    searchFilter?: string | null;
    siteIDs?: Array<string> | null;
};
export type StoryTableContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"StoryTableContainer_query">;
};
export type StoryTableContainerPaginationQuery = {
    readonly response: StoryTableContainerPaginationQueryResponse;
    readonly variables: StoryTableContainerPaginationQueryVariables;
};



/*
query StoryTableContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $statusFilter: STORY_STATUS
  $searchFilter: String
  $siteIDs: [ID!]
) {
  ...StoryTableContainer_query_2qeHoO
}

fragment StoryRowContainer_story on Story {
  id
  metadata {
    title
    author
    publishedAt
  }
  commentCounts {
    totalPublished
  }
  moderationQueues {
    reported {
      count
      id
    }
    pending {
      count
      id
    }
  }
  viewerCount
  site {
    name
    id
  }
  canModerate
  isClosed
  ...StoryStatusContainer_story
}

fragment StoryStatusContainer_story on Story {
  id
  status
  isArchiving
  isArchived
}

fragment StoryTableContainer_query_2qeHoO on Query {
  settings {
    multisite
    id
  }
  viewer {
    moderationScopes {
      sites {
        id
      }
    }
    id
  }
  stories(first: $count, after: $cursor, status: $statusFilter, query: $searchFilter, siteIDs: $siteIDs) {
    edges {
      node {
        id
        ...StoryRowContainer_story
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "searchFilter"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "siteIDs"
    } as any), v4 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "statusFilter"
    } as any), v5 = ({
        "kind": "Variable",
        "name": "siteIDs",
        "variableName": "siteIDs"
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v7 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any),
        ({
            "kind": "Variable",
            "name": "query",
            "variableName": "searchFilter"
        } as any),
        (v5 /*: any*/),
        ({
            "kind": "Variable",
            "name": "status",
            "variableName": "statusFilter"
        } as any)
    ], v8 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
        } as any),
        (v6 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "StoryTableContainerPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        {
                            "kind": "Variable",
                            "name": "searchFilter",
                            "variableName": "searchFilter"
                        },
                        (v5 /*: any*/),
                        {
                            "kind": "Variable",
                            "name": "statusFilter",
                            "variableName": "statusFilter"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "StoryTableContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v4 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "StoryTableContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v7 /*: any*/),
                    "concreteType": "StoriesConnection",
                    "kind": "LinkedField",
                    "name": "stories",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "StoryEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Story",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "StoryMetadata",
                                            "kind": "LinkedField",
                                            "name": "metadata",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "title",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "author",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "publishedAt",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentCounts",
                                            "kind": "LinkedField",
                                            "name": "commentCounts",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalPublished",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ModerationQueues",
                                            "kind": "LinkedField",
                                            "name": "moderationQueues",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ModerationQueue",
                                                    "kind": "LinkedField",
                                                    "name": "reported",
                                                    "plural": false,
                                                    "selections": (v8 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ModerationQueue",
                                                    "kind": "LinkedField",
                                                    "name": "pending",
                                                    "plural": false,
                                                    "selections": (v8 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "viewerCount",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Site",
                                            "kind": "LinkedField",
                                            "name": "site",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                },
                                                (v6 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "canModerate",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isClosed",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isArchiving",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isArchived",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v7 /*: any*/),
                    "filters": [
                        "status",
                        "query",
                        "siteIDs"
                    ],
                    "handle": "connection",
                    "key": "StoryTable_stories",
                    "kind": "LinkedHandle",
                    "name": "stories"
                }
            ]
        },
        "params": {
            "id": "9d2d7753262a19f7957fb97d39359edc",
            "metadata": {},
            "name": "StoryTableContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'e0acb9e2d6329b361ac0575904ee24f9';
export default node;
